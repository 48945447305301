import * as React from "react"
import { graphql, useStaticQuery } from "gatsby"

import Layout from "components/layout/layout"
import Seo from "components/layout/seo"

import ImgBackground from "components/heroes/ImgBackground"
import TitleBox from "components/titles/Titlebox"
import VimeoEmbed from "components/videos/video-vimeo"

import ContactCTABlock from "components/blocks/ContactCTA"

import SubmenuEnvi from "components/menus/submenu-envi"

import TurboHeadLogo from "images/svg/TurboHead.svg"

const HowWorksPage = () => {

    // using static header image, load here
    const data = useStaticQuery(
        graphql`
      query {
        heroImage: file(relativePath: { eq: "backgrounds/scrubbers-factory-completed.jpg" }) {
          childImageSharp {
            gatsbyImageData(
              width: 2000
            )
          }
        }
        turbohead: file(relativePath: { eq: "illustrations/TurboHead-Closeup-Web.jpg" }) {
          childImageSharp {
            gatsbyImageData(
              width: 2000
            )
          }
        }
      }
    `
    )

    return (
        <Layout>
            <Seo title="ENVI-Marine™ - How it works" />

            {/* Title banner */}
            <ImgBackground image={data.heroImage}>
                <div className="container m-auto py-10 lg:pb-0 md:pt-40 lg:pt-96 lg:flex">
                    <TitleBox title="How the Scrubbing Process Works" caption="ENVI-Marine™" />
                </div>
            </ImgBackground>

            {/* Content Area */}
            <section className="py-16 lg:py-32 pb-8 lg:pb-16">
                <div className="container">

                    <div className="lg:flex lg:space-x-12">

                        <div className="mb-12 lg:w-1/4">
                            <SubmenuEnvi className="" />
                        </div>

                        <div className="mb-12 lg:w-3/4">
                            <div className="prose max-w-none">
                                <p className="text-2xl">
                                    The ENVI‑Marine™ process is a simple concept. The flue gases are first quenched, then cleaned, by specialized frothing through pure seawater, using our patented TurboHead™ process before being discharged as harmless salts.
                                </p>
                                <VimeoEmbed src="https://player.vimeo.com/video/328635431?h=a82477dacf" title="ENVI-Marine Process Overview" className="mr-12" />

                                <div className="lg:flex lg:space-x-12 py-12">
                        <div className="mb-12 lg:w-1/2">
                            <h2 className="text-3xl mb-4">Functions by:</h2>
                            <ul>
                                <li>Using the natural alkalinity of seawater to absorb SO2 emissions produced by marine engines</li>
                                <li>Post treatment discharge of neutralized sulphur into sea</li>
                                <li>Using caustic soda (NaOH) as a supplemental reagent when transiting areas with low seawater alkalinity</li>
                            </ul>
                        </div>
                        <div className="mb-12 lg:w-1/2">
                            <h2 className="text-3xl mb-4">Flexible layout due to:</h2>
                            <ul>
                                <li>Patented horizontal head</li>
                                <li>Potential for component separation</li>
                                <li>Able to configure footprint to fit space available</li>
                                <li>Compact footprint due to efficiency</li>
                            </ul>
                        </div>
                    </div>

                            </div>
                        </div>
                    </div>

                </div>
            </section>


            {/* Content Area */}
            <ImgBackground className="py-16 lg:py-40 bg-archivebk bg-contain bg-right-top" image={data.turbohead}>
                <div className="container">

                    <div className="lg:flex lg:space-x-12">
                        <div className="pt-80 w-full lg:pt-0 lg:w-7/12">
                            <TurboHeadLogo className="bg-white p-8 w-80 mb-8 rounded dropshadow"/>
                            <h3 className="mb-4">How does it work?</h3>
                            <p>Water is continuously sprayed down onto the TurboHead™ Structure where it meets gas flowing upwards. The unique patented design of the TurboHead™ causes the gas and water to mix and create a turbulent ‘froth’, sometimes referred to as ‘foam’. It is the maximum surface area of the froth where the reaction takes place to clean the exhaust gas.</p>
                            <ol className="mt-8 list-standout space-y-4 font-bold">
                                <li>Nozzles, with carefully calculated water volume</li>
                                <li>‘Froth’ Layer, generated by interaction of gas and water</li>
                                <li>Baffles, designed to maximise interaction</li>
                                <li>Mesh, precision designed to encourage reaction</li>
                            </ol>
                        </div>
                        <div className="w-full lg:w-5/12"></div>
                    </div>

                </div>
            </ImgBackground>

            {/* CTA contact block */}
            <section className="py-16 lg:py-32">
                <ContactCTABlock />
            </section>

        </Layout>
    )
}

export default HowWorksPage
